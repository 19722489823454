import React from 'react';
import Layout from '../components/layout';
import ContactPage from '../components/ContactPage/contactPage';
import SEO from '../components/SEO/SEO';

const Kontakt = () => {
  return (
    <Layout>
      <SEO
        title='Kontakt'
        description='Wir sind für Sie da - Bowman Automobile AG, Ausserdorfstrasse 19, 5107 Schinznach-Dorf.'
      />
      <ContactPage />
    </Layout>
  );
};

export default Kontakt;
