import React from 'react';
import * as contactPageStyles from './contactPage.module.scss';

const ContactPage = () => {
  return (
    <div
      className={`p-0 container-fluid ${contactPageStyles.contactContainer}`}
    >
      <div className={`row w-100 m-0`}>
        <div className={`col-md-6 p-0`}>
          <iframe
            src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ3QzDors_kEcRzHklZ_316ek&key=AIzaSyCMed0tUZuUP_Zm2RS-XaNR4GDBHubOFkg'
            width='100%'
            height='100%'
            style={{ border: 0, opacity: 0.9 }}
            allowFullScreen=''
            loading='lazy'
            title='google maps'
          ></iframe>
        </div>
        <div className={`col-md-6 ${contactPageStyles.colRight}`}>
          <div className={`${contactPageStyles.form}`}>
            <h1>Kontaktformular</h1>
            <form action='https://formspree.io/f/mqkwzvpa' method='POST'>
              <div className={`${contactPageStyles.labels}`}>
                <label htmlFor='name'>
                  <strong>Vorname und Nachname:</strong>
                </label>
                <input id='name' name='name' required />
                <label htmlFor='email'>
                  <strong>E-Mail:</strong>
                </label>
                <input type='email' name='email' required />
                <label htmlFor='phone'>
                  <strong>Telefon:</strong>
                </label>
                <input type='phone' name='phone' />
                <label htmlFor='message'>
                  <strong>Ihre Nachricht:</strong>
                </label>
                <textarea name='message' rows='6' required></textarea>
              </div>
              <button type='submit'>Anfrage senden</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
