// extracted by mini-css-extract-plugin
export var buttonMd = "contactPage-module--button-md--da2f6";
export var carousel = "contactPage-module--carousel--bfa1a";
export var colRight = "contactPage-module--colRight--1756c";
export var contactContainer = "contactPage-module--contactContainer--ee451";
export var form = "contactPage-module--form--58085";
export var grey = "contactPage-module--grey--48d0d";
export var greyFont = "contactPage-module--greyFont--f46b2";
export var headerContainer = "contactPage-module--headerContainer--c90c2";
export var labels = "contactPage-module--labels--850a4";
export var spanBold = "contactPage-module--spanBold--aecb8";
export var yellow = "contactPage-module--yellow--0bd03";